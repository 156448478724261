<template>
    <div class="page-fwa-product-orders">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Fixed Wireless Orders</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-error v-model="errors.show" :message="errors.message"></app-error>

                    <app-table
                        class="fwa-product-orders-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appPagination,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'OrderType',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'OrderType',        title: 'Order Type' },
                { field: 'CreatedAtNanos',   title: 'Created At' },
                { field: 'UpdatedAtNanos',   title: 'Updated At' },
            ],

            cols: [
                { key: 'ProductInstanceUUID', title: 'Product Instance UUID'                                   },

                { key: 'CustomerUUID', title: 'Customer UUID', type: 'router-link', route: { name: 'customer-details' }, blank: true },

                { key: 'OrderType',           title: 'Order Type',           sort: { field: 'OrderType'      } },
                { key: 'CreatedAtNanos',      title: 'Created At',           sort: { field: 'CreatedAtNanos' } },
                { key: 'UpdatedAtNanos',      title: 'Updated At',           sort: { field: 'UpdatedAtNanos' } },
            ],

            product_orders: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getProductOrders()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getProductOrders()
        },

        onPageChange(page) {
            this.getProductOrders(page)
        },

        getProductOrders(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                ProductType: 'in|FWA_Speed,FWA_Volume',

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_product_order/FindProductOrdersPaginated', params)
                .then(({ ProductOrders, PageInfo }) => {
                    this.product_orders = ProductOrders

                    this.pagination.page = PageInfo.PageNumber
                    this.pagination.total = PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.product_orders = []

                    this.loading = false
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        rows() {
            const rows = []

            for (let i = 0, len = this.product_orders.length; i < len; i++) {
                const product_order = this.product_orders[i]

                rows.push({
                    uuid: product_order.UUID,
                    ProductInstanceUUID: product_order.ProductInstanceUUID ? product_order.ProductInstanceUUID : '?',

                    CustomerUUID: {
                        params: {
                            uuid: product_order.CustomerUUID,
                        },

                        text: product_order.CustomerUUID,
                    },

                    OrderType: product_order.OrderType ? product_order.OrderType : '?',
                    CreatedAtNanos: product_order.CreatedAtNanos ? formatDateNano(product_order.CreatedAtNanos, 'YYYY/MM/DD, HH:mm:ss') : '?',
                    UpdatedAtNanos: product_order.UpdatedAtNanos ? formatDateNano(product_order.UpdatedAtNanos, 'YYYY/MM/DD, HH:mm:ss') : '?',
                })
            }

            return rows
        },
    },
}
</script>

<style lang="scss">
.page-fwa-product-orders {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .fwa-product-orders-table {
        @include table-cols-width((345px, 415px, 275px, 175px, 175px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-fwa-product-orders {
        .fwa-product-orders-table {
            @include table-cols-width((175px, 200px, 145px, 90px, 90px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fwa-product-orders {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .fwa-product-orders-table {
            @include table-cols-width-mobile((110px, 165px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>